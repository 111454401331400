/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { calculateTimeLeft } from '../utils/utils';
import Seo from '../components/Seo';

const ComingSoonPage = () => {
  const data = useStaticQuery(graphql`
  {
    comingSoon:file(relativePath: {eq: "coming-soon.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    }
     logo:file(relativePath: {eq: "logo-white.png"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED )
      }
    },
    contentfulFooter {
      socials {
        url
        name
        imageIcon {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bottomFooterRight {
        heading
        name
        url
      }
    }
  }
`);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
  }, [timeLeft]);

  const socialData = data.contentfulFooter.socials;

  return (
    <div className="font-Nunito">
      <Seo title="Coming Soon" />
      <section className="md:min-w-screen min-w-none min-h-screen block md:container py-[20px] px-[20px] overflow-hidden relative bg-background">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="max-width-150px md:max-width-full cursor-pointer flex items-center flex-shrink-0 mr-6 w-[120px]">
            <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="logo" className="md:w-auto w-[70px]" />
          </div>
          <div className="flex mt-4 -mx-2">
            {socialData.map(({
              url, name, imageIcon,
            }, index) => (
              <a href={url} key={index} alt={name} className="mx-2 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label={name}>
                <GatsbyImage image={imageIcon.gatsbyImageData} alt="socials" className="w-[18px]" />
              </a>
            ))}
          </div>
        </div>
        <div className="min-h-full pt-[20px] min-w-ful px-0 text-gray-800 relative items-center text-center md:text-left mx-auto">
          <div className="grid grid-cols-1 items-center justify-items-center">
            <div className="lg:w-[40%] w-[70%] mx-auto relative inline-flex flex-col cursor-pointer mb-4 h-full object-contain">
              <GatsbyImage image={data.comingSoon.childImageSharp.gatsbyImageData} alt="coming-soon" />
            </div>
          </div>
          <div className="w-full mt-10 mx-auto">
            <div className="mb-6 md:mb-0 mx-auto">
              <div className="flex lg:gap-[1rem] gap-0 justify-center">
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">{String(timeLeft.days).padStart(2, '0')}</span>
                  <span className="text-[0.8rem] text-text w-full font-bold text-center mt-[0.1rem]">Days</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">:</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-centerjustify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">{String(timeLeft.hours).padStart(2, '0')}</span>
                  <span className="text-[0.8rem] text-text w-full font-bold text-center mt-[0.1rem]">Hours</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">:</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">
                    {String(timeLeft.minutes).padStart(2, '0')}
                  </span>
                  <span className="text-[0.8rem] text-text w-full font-bold text-center mt-[0.1rem]">Minutes</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">:</span>
                </div>
                <div className="w-[60px] md:w-[80px] lg:w-[100px] flex flex-col items-center justify-center">
                  <span className="w-full text-[2rem] md:text-[4rem] text-white text-center">
                    {String(timeLeft.seconds).padStart(2, '0')}
                  </span>
                  <span className="text-[0.8rem] text-text w-full font-bold text-center mt-[0.1rem] ">Seconds</span>
                </div>
              </div>
              <p className="text-white text-sm-1">
                {data.contentfulFooter.bottomFooterRight.heading}
                <a href={data.contentfulFooter.bottomFooterRight.url} rel="noopener noreferrer" className="ml-1 text-[#2793ed] hover:text-text" target="_blank">{data.contentfulFooter.bottomFooterRight.name}</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComingSoonPage;
